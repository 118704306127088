<template>
  <NuxtLayout name="base">
    <div class="base">
      <div class="container-dash">
        <slot />
      </div>
    </div>
  </NuxtLayout>
</template>

<style lang="scss" scoped>
@use '@/assets/scss/colors' as *;
@use '@/assets/scss/breakpoints' as *;
@use '@/assets/scss/sizes' as *;

.base {
  background: $grey100;
  margin-top: 56px;
  min-height: calc(100vh - 56px);
  padding: env(safe-area-inset-bottom, $doubleOff);

  @include tab {
    margin-top: 64px;
    min-height: calc(100vh - 64px);
    padding: env(safe-area-inset-bottom, $none);
  }
}

.container-dash {
  margin: $none;
  padding: $none $doubleOff;
  background: $bright;
  width: 100%;

  @include sm {
    padding: $none $quadruple;
  }

  @include md {
    padding: $none $sixfold;
    width: 100%;
  }

  @include tab {
    background: $transparent;
  }

  @include lg {
    padding: $none 64px;
    background: $transparent;
    width: 100%;
  }

  @include xl {
    padding: $none 104px;
  }
}
</style>
